import Banner from '../../assets/images/banner-green.png';
const TopLpjComponent = () => {
    return (
        <> 
            <div className='relative'>
                <img src={Banner} alt='banner' className='w-full' />
                <div className='flex items-center justify-center w-full absolute top-0 h-[100%]'>
                    <div className="container mx-auto text-white text-center md:px-5 px-3">
                        <h1 className='md:text-[3rem] text-[1.2rem] font-semibold md:leading-[72px] leading-[24px]'>Laporan Pertanggungjawaban</h1>
                        <p className='md:text-[1.2rem] text-[.4rem]'>Silahkan upload dokumen Laporan Pertanggungjawaban anda pada form dibawah</p>
                        <br className='md:block hidden' />
                        <div className='justify-center md:mt-5 mt-2 md:flex hidden'>
                            <hr className='border-white md:border-2' width="200px" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TopLpjComponent;