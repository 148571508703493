import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Hibah from '../../assets/images/hibah-1.png';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { useEffect, useState } from 'react';

import { useParams } from "react-router";
import Proposal from "../../service/Proposal";
import LoaderComponent from "../LoaderComponent";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TopDetail = ( ) => {
    const [ data, setData ] = useState({})
    const [ goals, setGoals ] = useState([])

    const { id } = useParams()
    const [ loading, setLoading ] = useState(true)
    useEffect(() => {
        const getDetailProposal = async (id) => {
            try {
                const response = await Proposal('detail', id);
                setData(response.data.data)
                setGoals(response.data.data.tujuan)
                setLoading(false)
            } catch (e) { console.error('error : ', e); setLoading(false) }
        }
        getDetailProposal(id)
    }, [id])

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <>
            { loading && (
                <LoaderComponent />
            )}
            <div className="md:grid md:grid-cols-3 md:gap-5 mt-4">
                <div className="col-span-1 mb-3 md:leading-[24px] leading-[18px]">
                <Slider {...settings}>
                    {data.images && data.images.map((img, index) => (
                        <div key={index} className='px-3'>
                            <img src={img.image_url ? img.image_url : Hibah} alt="gambar-proposal" className="w-[100%] rounded" />
                        </div>
                    ))}
                </Slider>
                </div>
                <div className="col-span-2 text-justify mb-3 md:leading-[24px] leading-[18px]">
                    <h1 className="md:text-[1.6rem] text-[1.2rem] md:leading-[28px] leading-[24px] font-semibold">{data.title}</h1>
                    <br />
                    <FontAwesomeIcon icon={faCalendar} className='me-2' />{data.tgl_pengajuan}
                </div>
                <div className="mb-3 md:leading-[24px] leading-[18px]">
                    <strong className="text-[1.05rem]">No. Permohonan</strong><br />
                    <span className='md:text-[1rem] text-[.75rem]'>{data.no_permohonan}</span>
                </div>
                <div className="mb-3 md:leading-[24px] leading-[18px]">
                    <strong className="text-[1.05rem]">Pengaju</strong><br />
                    <span className='md:text-[1rem] text-[.75rem]'>{data.name}</span>
                </div>
                <div className="mb-3 md:leading-[24px] leading-[18px]">
                    <strong className="text-[1.05rem]">Instansi</strong><br />
                    <span className='md:text-[1rem] text-[.75rem]'>
                        {data.dinas ? `${data.dinas.name}` : 'Instansi belum dipilih'}
                    </span>
                </div>
                <div className="mb-3 md:leading-[24px] leading-[18px] col-span-3">
                    <strong className="text-[1.05rem]">Alamat Lengkap</strong><br />
                    <span className='md:text-[1rem] text-[.75rem]'>{data.address}, Desa. {data.desa}, Kecamatan {data.kecamatan}, Kabupaten Subang</span>
                </div>
                <div className="mb-3 md:leading-[24px] leading-[18px] col-span-3">
                    <strong className="text-[1.05rem]">Latar Belakang</strong><br />
                    <span className='md:text-[1rem] text-[.75rem]'>{data.background}</span>
                </div>
                <div className='mb-3 md:leading-[24px] leading-[18px] col-span-3'>
                    <strong className="text-[1.05rem]">Maksud dan Tujuan</strong><br />
                    <ol className='md:text-[1rem] text-[.75rem] ms-5'>
                        {goals && goals.map((goals, index) => (
                            <li key={index}>{goals.content}</li>
                        ))}
                    </ol>
                </div>
                
                <div className="mb-3 md:leading-[24px] leading-[18px] col-span-3">
                    <strong className="text-[1.05rem]">Deskripsi</strong><br />
                    <span className='md:text-[1rem] text-[.75rem]'>{data.desc}</span>
                </div>

                <div className='mb-3 md:leading-[24px] leading-[18px] col-span-3'>
                    <strong className='md:text-[1.5rem] text-[1.1rem]'>
                        Rencana Penggunaan Dana
                    </strong>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-3 col-span-3">
                    <div className="md:leading-[24px] leading-[18px]">
                        <strong className="text-[1.05rem]">Dana yang Diajukan</strong><br />
                        <span className='md:text-[1rem] text-[.75rem]'>Rp {data.total_fund_fix}</span>
                    </div>
                    <div className="md:leading-[24px] leading-[18px]">
                        <strong className="text-[1.05rem]">Evaluasi Instansi</strong><br />
                        <span className='md:text-[1rem] text-[.75rem]'>Rp {data.dinas_evaluation_fund_fix}</span>
                    </div>
                    <div className="md:leading-[24px] leading-[18px]">
                        <strong className="text-[1.05rem]">Penyesuaian  TAPD</strong><br />
                        <span className='md:text-[1rem] text-[.75rem]'>Rp {data.adjustment_fund_fix}</span>
                    </div>
                    <div className="md:leading-[24px] leading-[18px]">
                        <strong className="text-[1.05rem]">Dana yang Disetujui</strong><br />
                        <span className='md:text-[1rem] text-[.75rem]'>Rp {data.final_fund_fix}</span>
                    </div>
                </div>


            </div>
        </>
    )
}
export default TopDetail;