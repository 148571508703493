/* eslint-disable jsx-a11y/anchor-is-valid */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Banner from '../../assets/images/banner-sbg.jpg';
import { faPlayCircle, faTimesRectangle } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

import LandingPageSettings from '../../service/LandingPageSettings';
import Cookies from 'js-cookie';

const BannerComponent = () => {
    const [ dataBanner, setDataBanner ] = useState({
        banner: Banner,
        link: '#',
        news: ''
    });
    useEffect(() => {
        const getDataBanner = async ( ) => {
            try {
                const response = await LandingPageSettings()
                setDataBanner({
                    banner: response.data.data[0].image_url,
                    link: response.data.data[1].content,
                    news: response.data.data[5].content
                });
            } catch (e) {
                console.error('error : ', e);
            }
        }    
        getDataBanner()
    }, [])
    
    const [ openPopUp, setPopUp ] = useState(true)

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
        if (!openPopUp || keyCode !== 27) return;
            setPopUp(false);
            Cookies.set('information-popup', 1, { expires: 1 / 6 })
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    },[]);

    useEffect(() => {
       if(Cookies.get('information-popup')){
            setPopUp(false)
       } else { setPopUp(true) };
    },[])

    return(
        <>
         {dataBanner.news && ( // When News is empty, won't show the news
            <div id="login-modal" tabIndex="-1" aria-hidden="true" className={`${!openPopUp ? 'hidden' : 'block'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100vh] max-h-full`}>
                <div className="relative p-4 w-full h-[90vh] max-h-full flex justify-center items-center">
                    <div className={`md:p-5 p-3 bg-white rounded-lg shadow border border-2 border-gray-200 text-center md:w-[50%] w-full`}>
                        <div className="header-modal flex border-b pb-3 mb-3">
                            <h1 className='font-bold'>Informasi Terkini</h1>
                            <h1 className='ms-[auto] cursor-pointer' onClick={() => {
                                setPopUp(false)
                                Cookies.set('information-popup', 1, { expires: 1 / 6 })
                            }}><FontAwesomeIcon icon={faTimesRectangle}  className='text-lg'/></h1>
                        </div>
                        <div className='body-modal text-justify' dangerouslySetInnerHTML={{ __html: dataBanner.news }}></div>
                    </div>
                </div>
            </div>
        )}

            <div className="relative lg:min-h-[500px] md:min-h-[300px] min-h-[150px]">
                {/* This banner loader before the landingpage banner shows up */}
                <img src={dataBanner.banner} alt='banner' className='w-full' />
                <div className="absolute bg-secondary opacity-[80%] w-full h-[100%] top-0 left-0" />
                <div className="absolute top-[50%] translate-y-[-50%] w-full">
                    <div className="flex justify-center text-white">
                        <div className='text-center md:basis-1/2'>
                            <h1 className='lg:text-[4rem] md:text-[2.3rem] text-[1.2rem] lg:mb-[-25px] md:mb-[-10px] mb-[-8px] font-semibold'>SIABAH</h1>
                            <h1 className='lg:text-[4rem] md:text-[2.3rem] text-[1.2rem] font-regular mb-2'>Kabupaten Subang</h1>
                            <div className='lg:mx-[7.5rem] mx-[2rem] lg:text-[1rem] md:text-[.8rem] text-[.6rem]'>
                                <span className='md:block hidden'>SiAbah adalah website yang dibuat khusus untuk pengajuan hibah & Bansos Online</span>
                                <div className='lg:mt-[3rem] md:mt-[1.5rem] mt-[1rem]'>
                                    <a href={dataBanner.link} target="__blank">
                                        <div className='flex'>
                                            <FontAwesomeIcon icon={faPlayCircle} className='lg:text-[2rem] md:text-[1.5rem] text-[1.2rem] me-2' />
                                            <div className='text-left'>
                                                <p className='font-semibold'>Video Tentang SiAbah</p>
                                                <p>Berikut adalah video penjelasan tentang SiAbah</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BannerComponent;