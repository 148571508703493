import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Hibah from '../../assets/images/hibah-1.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ProcessingProposal = (query) => {
    const dataQuery = query.data.data;
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <>
            {dataQuery && dataQuery.map((item, index) => (
                <>
                    {console.log('Item data:', item)} {/* Console log untuk melihat data */}
                    <div key={index} className="border md:mb-5 mb-3 border-gray-300 rounded-lg overflow-hidden">
                        <div className="bg-gray-200 text-black py-3 lg:w-[20%] w-[75%] text-center rounded-br-2xl font-semibold">
                            <FontAwesomeIcon icon={faClock} className="me-2" /> Sedang Diproses
                        </div>
                        <br />
                        <div className="lg:px-[5rem] md:pb-[2rem] md:pt-[2rem] pb-[2rem] px-3 text-black">
                            <h1 className="md:text-[2rem] text-[1.2rem] md:leading-[40px] leading-[24px] font-semibold">{item.title}</h1>
                            <div className="lg:grid lg:grid-cols-3 lg:gap-5 my-4">
                                <div className="col-span-1 mb-3">
                                    <Slider {...settings}>
                                        {item.images && item.images.map((img, index) => (
                                            <img key={index} src={img.image_url ? img.image_url : Hibah} alt="gambar-proposal" className="w-full" />
                                        ))}
                                    </Slider>
                                </div>
                                <div className="col-span-2 text-justify mb-3">
                                    {item.desc}
                                </div>
                                <div className="mb-3">
                                    <strong className="text-[1.05rem]">No. Permohonan</strong><br />
                                    {item.no_permohonan}
                                </div>
                                <div className="mb-3">
                                    <strong className="text-[1.05rem]">Pengaju</strong><br />
                                    {item.name}
                                </div>
                                <div className="mb-3">
                                    <strong className="text-[1.05rem]">Tanggal Pengajuan</strong><br />
                                    {item.tgl_pengajuan}
                                </div>
                                <div className="mb-3">
                                    <strong className="text-[1.05rem]">Dana yang diajukan</strong><br />
                                    Rp {item.total_fund_fix}
                                </div>
                                <div className="mb-3">
                                    <strong className="text-[1.05rem]">Status</strong><br />
                                    {item.status === 'pending by user' ? (
                                        <p className="mt-2">
                                            <span className="bg-amber-600 text-white capitalize p-2 rounded-lg">{item.status}</span>
                                        </p>
                                    ): (
                                        <div className="flex items-center">
                                            <span className="bg-primary text-white capitalize p-2 rounded-lg">{item.status}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <strong className="text-[1.05rem]">Keterangan</strong><br />
                                    {item.status === 'pending by user' ? (
                                        <div className="mb-5">
                                            <p className="mt-2">
                                            {item.proposal_log.length > 0 ? (
                                                <span className="mb-2">
                                                    {item.proposal_log[item.proposal_log.length - 1].ket}
                                                </span>
                                            ) : (  
                                                <span className="mb-2">Keterangan tidak tersedia</span>
                                            )}
                                            </p>
                                        </div>
                                    ) : item.status === 'pending by dinas' ? (
                                        <div className="mb-5">
                                            {item.dinas && (
                                                <p className="mt-2">
                                                    <span className="mb-2">
                                                        Proposal sedang diproses oleh {item.dinas.name}
                                                    </span>
                                                </p>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="flex items-center">
                                            <span className="capitalize font-bold text-gray-600">{item.status}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex justify-end w-full">
                                <a href={`/riwayat-proposal/detail/${item.slug}`} className="bg-primary text-white rounded-lg px-3 py-2">Lihat Selengkapnya</a>
                            </div>
                        </div>
                    </div>
                </>
            ))}
            {dataQuery && dataQuery.length === 0 && (
                <div className="border md:mb-5 mb-3 border-gray-300 rounded-lg overflow-hidden flex justify-center items-center min-h-[40vh]">
                    <h1 className="text-[2rem] italic text-center px-2">Tidak ada proposal yang diproses</h1>
                </div>
            )}
        </>
    )
}

export default ProcessingProposal;
