/* eslint-disable jsx-a11y/anchor-is-valid */
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Proposal from "../../service/Proposal";

const DocumentDetail = () => {
    const { id } = useParams()
    const assets = process.env.REACT_APP_ASSETS_KEY;
    const [ data, setData ] = useState({})
    useEffect(() => {
        const getDetailProposal = async (id) => {
            try {
                const response = await Proposal('detail', id);
                setData(response.data.data)
            } catch (e) { console.error('error : ', e);}
        }
        getDetailProposal(id)
    }, [id])
    return(
        <>  
             <div className='col-span-3 mt-5'>
                <strong className='md:text-[1.5rem] text-[1.1rem]'>
                    Dokumen Proposal
                </strong>
            </div>
            <div className="lg:grid lg:grid-cols-3 lg:gap-5 mb-4 mt-3">
                <div className="md:mb-0 mb-3 md:mx-0 mx-3">
                    <label className="md:text-[.9rem] text-[.8rem]">Proposal Pengajuan</label>
                    <label className="md:text-[.9rem] text-[.8rem]">
                        <div className="w-full min-h-[125px] border-dashed border-2 bg-gray-100 rounded-lg border flex items-center justify-center mt-1">
                            <a href={data.proposal_file_url} target="__blank" className="text-center">
                                <FontAwesomeIcon icon={faEye} className="bg-gray-200 rounded-lg p-2" /><br />
                                <span className="text-gray-400">Lihat File</span><br />
                                <span className="whitespace-normal break-all">{data.proposal_file}</span>
                            </a>
                        </div>
                    </label>
                </div>
                {data.dokumen_wajib && data.dokumen_wajib.length > 0 && data.dokumen_wajib.map((item, index) => (
                    <div key={index} className="md:mb-0 mb-3 md:mx-0 mx-3">
                        <label className="md:text-[.9rem] text-[.8rem]">{item.name}</label>
                        <label htmlFor="proposal" className="md:text-[.9rem] text-[.8rem]">
                            <div className="w-full min-h-[125px] border-dashed border-2 bg-gray-100 rounded-lg border flex items-center justify-center mt-1">
                                <a href={`${assets}/file-uploads/${item.pivot.content}`} target="__blank" className="text-center">
                                    <FontAwesomeIcon icon={faEye} className="bg-gray-200 rounded-lg p-2" /><br />
                                    <span className="text-gray-400">Lihat File</span><br />
                                    <span className="whitespace-normal break-all">{item.pivot.content}</span>
                                </a>
                            </div>
                        </label>
                    </div>
                ))}
                {data.dokumen_pendukung && data.dokumen_pendukung.length > 0 && data.dokumen_pendukung.map((item, index) => (
                    <div key={index} className="md:mb-0 mb-3 md:mx-0 mx-3">
                        <label className="md:text-[.9rem] text-[.8rem]">Dokumen Pendukung</label>
                        <label htmlFor="accountPhotocopy" className="md:text-[.9rem] text-[.8rem]">
                            <div className="w-full min-h-[125px] border-dashed border-2 bg-gray-100 rounded-lg border flex items-center justify-center mt-1">
                                <a href={item.dokumen_file_url} target="__blank" className="text-center">
                                    <FontAwesomeIcon icon={faEye} className="bg-gray-200 rounded-lg p-2" /><br />
                                    <span className="text-gray-400">Lihat File</span><br />
                                    <span className="whitespace-normal break-all">{item.dokumen_file}</span>
                                </a>
                            </div>
                        </label>
                    </div>
                ))}
            </div>
            <div className='col-span-2 mt-8'>
                <strong className='md:text-[1.5rem] text-[1.1rem]'>
                    Dokumen LPJ
                </strong>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-5 bg-gray-100 py-3 rounded-lg border border-gray-200 col-span-3">
                    <div className="md:leading-[24px] leading-[18px] text-center mt-3">
                        <strong className="text-[1.05rem]">Status LPJ</strong><br />
                        <p className="mt-2"> 
                        {data.lpj_proposals && data.lpj_proposals.length > 0 ? (
                                        data.lpj_proposals.map((lpj, index) => (
                                            <span 
                                                key={index} 
                                                className={`text-white p-2 text-lg rounded-lg mt-5 capitalize ${
                                                    lpj.status === 'pending' ? 'bg-gray-500' :
                                                    lpj.status === 'approved' ? 'bg-primary' :
                                                    lpj.status === 'returned' ? 'bg-orange-600' :
                                                    lpj.status === 'declined' ? 'bg-red-600' : ''
                                                }`}
                                            >
                                                &nbsp;&nbsp;{lpj.status}&nbsp;&nbsp;
                                            </span> 
                                        ))
                                    ) : (
                                        <span className="text-gray-500">Belum upload dokumen LPJ</span>
                                    )}
                                    </p>
                    </div>
                    <div className="md:leading-[24px] leading-[18px] text-center mt-3">
                        <strong className="text-[1.05rem]">Keterangan</strong><br />
                        <p className="mt-2">
                        {data.lpj_proposals && data.lpj_proposals.length > 0 ? (
                            data.lpj_proposals.map((lpj, index) => (
                                <span key={index} className="text-base capitalize justify-center items-center">
                                    {lpj.status === 'pending' && "LPJ sedang diproses"}
                                    {lpj.status === 'approved' && "LPJ telah disetujui"}
                                    {lpj.status === 'returned' && lpj.status_details}
                                    {lpj.status === 'declined' && lpj.status_details}
                                    {lpj.status === null && "Belum upload LPJ"}
                                </span>
                            ))
                        ) : (
                            <span className="text-gray-500">Dokumen LPJ tidak ada</span>
                        )}
                        </p>
                    </div>
                    <div className="md:leading-[24px] leading-[18px]">
                        
                    </div>
                </div>

            {/* berkas lpj akan muncul disini */}
            <div className="lg:grid lg:grid-cols-3 lg:gap-5 mb-4 mt-3">
                {data.lpj_proposals && data.lpj_proposals.length > 0 && data.lpj_proposals.map((lpj, index) => (
                    <div key={index} className="md:mb-0 mb-3 md:mx-0 mx-3">
                        <label className="md:text-[.9rem] text-[.8rem]">Laporan Pertanggung Jawaban</label>
                        <label htmlFor="lpjProposal" className="md:text-[.9rem] text-[.8rem]">
                            <div className="w-full min-h-[125px] border-dashed border-2 bg-gray-100 rounded-lg border flex items-center justify-center mt-1">
                                <a href={lpj.lpj_file_url} target="__blank" className="text-center">
                                    <FontAwesomeIcon icon={faEye} className="bg-gray-200 rounded-lg p-2" /><br />
                                    <span className="text-gray-400">Lihat File</span><br />
                                    <span className="whitespace-normal break-all">{lpj.lpj_file}</span>
                                </a>
                            </div>
                        </label>
                    </div>
                ))}
                {data.lpj_documents && data.lpj_documents.length > 0 && data.lpj_documents.map((doc, index) => (
                    <div key={index} className="md:mb-0 mb-3 md:mx-0 mx-3">
                        <label className="md:text-[.9rem] text-[.8rem]">Dokumen Lainnya</label>
                        <label htmlFor="lpjDocument" className="md:text-[.9rem] text-[.8rem]">
                            <div className="w-full min-h-[125px] border-dashed border-2 bg-gray-100 rounded-lg border flex items-center justify-center mt-1">
                                <a href={doc.document_file_url} target="__blank" className="text-center">
                                    <FontAwesomeIcon icon={faEye} className="bg-gray-200 rounded-lg p-2" /><br />
                                    <span className="text-gray-400">Lihat File</span><br />
                                    <span className="whitespace-normal break-all">{doc.document_file}</span>
                                </a>
                            </div>
                        </label>
                    </div>
                ))}
            </div>



            { data.status === 'pending by user' ? (
                <button 
                    onClick={() => document.location.href=`/riwayat-proposal/edit/${data.slug}`}
                    className="bg-orange-600 hover:bg-orange-700 w-full p-3 rounded-md mt-8 font-semibold text-white">
                
                    Edit Data

                </button>
            ) : data.status === 'approved'  && !data.lpj_proposals?.length ? (
                <button 
                    onClick={() => document.location.href=`/input-lpj/${data.slug}`}
                    className="bg-primary hover:bg-green-600 w-full p-3 rounded-md mt-8 font-semibold text-white">
                
                    Input Laporan Pertanggujawaban

                </button>
            ) : ('')}
            <button 
                    onClick={() => document.location.href=`/riwayat-proposal`}
                    className="hover:bg-green-700 text-green-800 border border-primary w-full p-3 rounded-md mt-4 font-semibold hover:text-white">
                
                    Kembali ke List

            </button>
        </>
    )
}
export default DocumentDetail;