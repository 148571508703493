import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Hibah from '../../assets/images/hibah-1.png';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const SuccessProposal = ( query ) => {
    const dataQuery = query.data.data;
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <>
            {dataQuery && dataQuery.map((item, index) => (
                <div key={index} className="border md:mb-5 mb-3 border-gray-300 rounded-lg overflow-hidden">
                    <div className="bg-primary text-white py-3 md:w-[20%] w-[75%] text-center rounded-br-2xl font-semibold">
                        <FontAwesomeIcon icon={faCheck} className="me-2" /> Disetujui
                    </div>
                    <br />
                    <div className="lg:px-[5rem] md:pb-[2rem] md:pt-[2rem] pb-[2rem] px-3 text-black">
                        <h1 className="md:text-[2rem] text-[1.2rem] md:leading-[40px] leading-[24px] font-semibold">{item.title}</h1>
                        <div className="lg:grid lg:grid-cols-4 lg:gap-5 my-4">
                            <div className="col-span-1 mb-3">
                            <Slider {...settings}>
                                {item.images && item.images.map((img, index) => (
                                    <img key={index} src={img.image_url ? img.image_url : Hibah} alt="gambar-proposal" className="w-full" />
                                ))}
                            </Slider>
                            </div>
                            <div className="col-span-3 text-justify mb-3">
                                {item.desc}
                            </div>
                            <div className="mb-3">
                                <strong className="text-[1.05rem]">No. Permohonan</strong><br />
                                {item.no_permohonan}
                            </div>
                            <div className="mb-3">
                                <strong className="text-[1.05rem]">Nama Pengaju</strong><br />
                                {item.name}
                            </div>
                            <div className="mb-3">
                                <strong className="text-[1.05rem]">Dana yang diajukan</strong><br />
                                Rp {item.total_fund_fix}
                            </div>
                            <div className="mb-3">
                                <strong className="text-[1.05rem]">Tanggal Pengajuan</strong><br />
                                {item.tgl_pengajuan}
                            </div>
                            <div className="mb-3">
                                <strong className="text-[1.05rem]">Status Proposal</strong><br />
                                <p className="mt-2">
                                    <span  className="bg-primary text-white p-2 rounded-lg">Disetujui</span>
                                </p>
                            </div>
                            <div className="mb-3">
                                <strong className="text-[1.05rem]">Keterangan Proposal</strong><br />
                                <div className="flex items-center">
                                <p className="mt-2">
                                    <span className="text-base capitalize">Proposal telah disetujui</span>
                                    </p>
                                </div>
                            </div>
                            <div className="mb-3">
                                <strong className="text-[1.05rem]">Status LPJ</strong><br />
                                <p className="mt-2">
                                    {item.lpj_proposals && item.lpj_proposals.length > 0 ? (
                                        item.lpj_proposals.map((lpj, index) => (
                                            <span 
                                                key={index} 
                                                className={`text-white p-2 rounded-lg capitalize ${
                                                    lpj.status === 'pending' ? 'bg-gray-500' :
                                                    lpj.status === 'approved' ? 'bg-primary' :
                                                    lpj.status === 'returned' ? 'bg-orange-600' :
                                                    lpj.status === 'declined' ? 'bg-red-600' : ''
                                                }`}
                                            >
                                                {lpj.status}
                                            </span> 
                                        ))
                                    ) : (
                                        <span className="text-gray-500">Tidak ada dokumen</span>
                                    )}
                                </p>
                            </div>

                            <div className="mb-3">
                                <strong className="text-[1.05rem]">Keterangan LPJ</strong><br />
                                <div className="flex items-center">
                                    <p className="mt-2">
                                        {item.lpj_proposals && item.lpj_proposals.length > 0 ? (
                                            item.lpj_proposals.map((lpj, index) => (
                                                <span key={index} className="text-base capitalize">
                                                    {lpj.status === 'pending' && "LPJ sedang diproses"}
                                                    {lpj.status === 'approved' && "LPJ telah disetujui"}
                                                    {lpj.status === 'returned' && lpj.status_details}
                                                    {lpj.status === 'declined' && lpj.status_details}
                                                    {lpj.status === null && "Belum upload LPJ"}
                                                </span>
                                            ))
                                        ) : (
                                            <span className="text-gray-500">Belum upload berkas LPJ</span>
                                        )}
                                    </p>
                                </div>
                            </div>

                        </div>     
                        <div className="flex justify-end w-full space-x-3">

                            {item.status == 'approved' && !item.lpj_proposals?.length ? (
                                <a href={`/input-lpj/${item.slug}`} className="bg-orange-600 text-white rounded-lg px-3 py-2">Input LPJ</a>
                            ) : ('')}
                            
                            {item.lpj_proposals && item.lpj_proposals.length > 0 ? (
                                item.lpj_proposals.map((lpj, index) => (
                                    lpj.status == 'returned' ? ( // Tambahkan kondisi ini
                                        <a key={index} href={`/edit-lpj/${item.slug}`} className="bg-orange-600 text-white rounded-lg px-3 py-2">
                                            &nbsp;&nbsp;Edit LPJ&nbsp;&nbsp;
                                        </a>
                                    ) : null
                                ))
                            ) : null}
                            <a href={`/riwayat-proposal/detail/${item.slug}`} className="bg-primary text-white rounded-lg px-3 py-2">
                                &nbsp;&nbsp;Lihat Selengkapnya&nbsp;&nbsp;
                            </a> 
                        </div>
                    </div>
                </div> 
            ))}
            {dataQuery && dataQuery.length === 0 && (
                <div className="border md:mb-5 mb-3 border-gray-300 rounded-lg overflow-hidden flex justify-center items-center min-h-[40vh]">
                    <h1 className="text-[2rem] italic text-center px-2">Tidak ada proposal yang disetujui</h1>
                </div>
            )}
        </>
    )
}
export default SuccessProposal;