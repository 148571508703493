import { useEffect, useState, useRef  } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../../components/DataComponent.css';

// next kita ambil gambar dari Landing page 
import jawara1 from '../../assets/images/sbg.png';
// import jawara2 from '../../assets/images/jawara-2.jpg';
// import jawara3 from '../../assets/images/jawara-3.jpg';
// import jawara4 from '../../assets/images/jawara-4.jpg';
// import jawara5 from '../../assets/images/jawara-5.jpg';
// import jawara6 from '../../assets/images/jawara-6.jpg';
// import jawara7 from '../../assets/images/jawara-7.jpg';
// import jawara8 from '../../assets/images/jawara-8.jpg';
// import jawara9 from '../../assets/images/jawara-9.jpg';

import LandingPageSettings from '../../service/LandingPageSettings';
import ProposalSummary from '../../service/ProposalSummary';

const DataComponent = () => {

    const images = [
        jawara1
    ];

    const [dataGambar, setDataGambar] = useState(images[0]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const slideContainerRef = useRef(null); 
    
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 4000);

        return () => clearInterval(intervalId);
    }, [images]);

    useEffect(() => {
        // Update the height of slide-container1 based on its content
        if (slideContainerRef.current) {
            const height = slideContainerRef.current.scrollHeight;
            slideContainerRef.current.style.height = `${height}px`;
        }
    }, [currentImageIndex]); // Trigger when the image changes

    useEffect(() => {
        const getDataBanner = async ( ) => {
            try {
                const response = await LandingPageSettings()
                setDataGambar(response.data.data[2].image_url);
            } catch (e) {
                console.error('error : ', e);
            }
        }    
        getDataBanner()
    }, [])

    const [ summary, setSummary ] = useState([
        {name: 'Proposal Masuk', total: 0, accepted: 0, declined: 0, returned: 0},
        {name: 'Diproses oleh TU', total: 0, accepted: 0, declined: 0, returned: 0},
        {name: 'Diproses oleh Bupati thp 1', total: 0, accepted: 0, declined: 0, returned: 0},
        {name: 'Diproses oleh ASDA', total: 0, accepted: 0, declined: 0, returned: 0},
        {name: 'Diproses oleh Dinas', total: 0, accepted: 0, declined: 0, returned: 0},
        {name: 'Diproses oleh TAPD', total: 0, accepted: 0, declined: 0, returned: 0},
        {name: 'Diproses oleh Bupati', total: 0, accepted: 0, declined: 0, returned: 0},
    ])

    useEffect(() => {
        const getDataSummary = async () => {
            try {
                const request = await ProposalSummary()
                const response = request.data.data
                setSummary([
                    {name: 'Proposal Masuk', total: response.total, accepted: response.approved, declined: response.declined, returned: (response.returned_by_tu + response.returned_by_asda + response.returned_by_dinas + response.returned_by_tapd + response.returned_by_bupati)},
                    {name: 'Diproses oleh TU', total: response.pending_by_tu, accepted: response.approved_by_tu, declined: response.declined_by_tu, returned: response.returned_by_tu},
                    {name: 'Diproses oleh Bupati thp 1', total: response.pending_by_bupati_tahap1, accepted: response.approved_by_bupati_tahap1, declined: response.declined_by_bupati_tahap1, returned: response.returned_by_bupati_tahap1},
                    {name: 'Diproses oleh ASDA', total: response.pending_by_asda, accepted: response.approved_by_asda, declined: response.declined_by_asda, returned: response.returned_by_asda},
                    {name: 'Diproses oleh Dinas', total: response.pending_by_dinas, accepted: response.approved_by_dinas, declined: response.declined_by_dinas, returned: response.returned_by_dinas},
                    {name: 'Diproses oleh TAPD', total: response.pending_by_tapd, accepted: response.approved_by_tapd, declined: response.declined_by_tapd, returned: response.returned_by_tapd},
                    {name: 'Diproses oleh Bupati', total: response.pending_by_bupati, accepted: response.approved_by_bupati, declined: response.declined_by_bupati, returned: response.returned_by_bupati},
                ])
            } catch (e){
                console.error('error', e)
            }
        }
        getDataSummary();
    }, [])

    return (
        <div className="container mx-auto md:px-[7rem] px-[2rem] md:py-[3rem] py-[2rem]">
            <div className="lg:flex align-center justify-center">
                {/* Ini slider  */}

                <div className="lg:basis-2/5 lg:hidden mb-5 slide-container">
                    <TransitionGroup className="slide-container">
                        <CSSTransition
                            key={currentImageIndex}
                            timeout={500}
                            classNames="slide"
                        >
                            <img
                                src={images[currentImageIndex]}
                                alt="Slide"
                                className="w-full rounded-2xl slide-image"
                            />
                        </CSSTransition>
                    </TransitionGroup>
                </div>

                <div className="lg:basis-3/5">
                    <div className="md:mx-4">
                        <div>
                            <h1 className="font-bold md:text-[2rem] text-[1.5rem]">Jumlah Data & Status Proposal</h1>
                            <hr className="border-black w-[200px] border-2 md:my-4 my-3" />
                            <p className="text-justify">
                                Berikut adalah jumlah dan status pengajuan Proposal Hibah dan Bantuan Sosial Online aplikasi 
                                SIABAH dari keseluruhan tahun ke tahun di Kabupaten Subang
                            </p>
                            <p className="text-justify mt-2">
                                Dengan adanya hal ini masyarakat dapat ikut memantau data Pengajuan Hibah & Bantuan Sosisal dan 
                                hal ini ditujukan dari aspek transparansi kepada masyarakat Kabupaten Subang
                            </p>
                        </div>
                        <br />
                        <div className='md:grid lg:grid-cols-3 md:grid-cols-2 md:gap-5'>
                            {summary.map((item, index) => (
                                <div key={index} className='group shadow-xl border rounded-xl md:px-3 md:pb-2 pb-3 px-3 mb-2'>
                                    {/* yang ini saat hover hidden */}
                                    <div className='group-hover:hidden cursor-pointer py-2.5'>
                                        <h1 className='font-bold text-[2rem]'>{item.total}</h1>
                                        <p className='font-sm font-normal text-pretty text-[13px]'>{item.name}</p>
                                    </div>

                                    {/* hanya saat hover kebukanya */}
                                    <div className={`bg-white group-hover:block hidden animate__animated animate__fadeIn cursor-pointer`}>
                                        <ul className='mx-2 py-2'>
                                            <li>Diterima: {item.accepted}</li>
                                            <li>Ditolak: {item.declined}</li>
                                            <li>Dikembalikan: {item.returned}</li>
                                        </ul>
                                    </div>
                                </div>    
                            ))}
                        </div>
                    </div>
                </div>
                
                <div ref={slideContainerRef} className="lg:basis-2/5 lg:block hidden slide-container1 pl-2 rounded-1x1">
                    <TransitionGroup className="slide-container">
                        <CSSTransition
                            key={currentImageIndex}
                            timeout={500}
                            classNames="slide lg:basis-2/5 lg justify-center"
                        >
                            <img
                                src={images[currentImageIndex]}
                                alt="Slide"
                                className="slide-image1"
                            />
                        </CSSTransition>
                    </TransitionGroup>
                </div>
                                
            </div>
        </div>
    )
}
export default DataComponent;