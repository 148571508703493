import axios from 'axios';
import Cookies from 'js-cookie';

const api = process.env.REACT_APP_API_KEY;

export const storeLpjData = async (slug, data) => { 
    const bearer = Cookies.get('__token');
    try {
        const response = await axios.post(`${api}/lpj/store/${slug}`, data, {
            headers: {
                Authorization: `Bearer ${bearer}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data; 
    } catch (error) {
        throw error.response.data; 
    }
};

export const updateLpjData = async (slug, data) => { 
    const bearer = Cookies.get('__token');
    try {
        const response = await axios.post(`${api}/lpj/update/${slug}`, data, {
            headers: {
                Authorization: `Bearer ${bearer}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data; 
    } catch (error) {
        throw error.response.data; 
    }
};

// Fungsi utama untuk menangani operasi LPJ
export const LpjStore = (query, slug, data) => {
    if (query === 'store') {
        return storeLpjData(slug, data);
    } else if (query === 'update') {
        return updateLpjData(slug, data);
    } else {
        throw new Error('Query tidak dikenal'); // Menangani query yang tidak dikenal
    }
};
