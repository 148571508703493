import { useParams } from 'react-router-dom'; // Import useParams untuk mengambil ID dari URL
import CopyrightComponent from "../../components/CopyrightComponent";
import LpjForm from "../../components/lpj-edit/LpjForm";
import TopLpjComponent from "../../components/lpj-edit/TopLpjComponent";
import NavbarComponent from "../../components/navbar-components/NavbarComponent";

function LpjRegistrationEdit() {
    const { slug } = useParams(); 

    return (
        <>
            <NavbarComponent />
            <TopLpjComponent />
            <LpjForm slug={slug} />
            <CopyrightComponent />
        </>
    );
}

export default LpjRegistrationEdit;
