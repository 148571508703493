import React, { useState } from 'react';
import { storeLpjData } from '../../service/LpjStore';
import Swal from "sweetalert2";
import { faCheckCircle, faPlusCircle, faTrash, faUpload, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LpjForm = ({ slug }) => {
    const [lpjFile, setLpjFile] = useState(null);
    // Inisialisasi 2 dokumen tambahan secara default
    const [documents, setDocuments] = useState([null, null]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleLpjFileChange = (e) => {
        setLpjFile(e.target.files[0]);
    };

    const handleDocumentsChange = (e, index) => {
        const file = e.target.files[0];
        const updatedDocs = [...documents];
        updatedDocs[index] = file;
        setDocuments(updatedDocs);
    };

    const addDokumen = () => {
        setDocuments((prevDocs) => [...prevDocs, null]);
    };

    const removeDocument = (index) => {
        setDocuments((prevDocs) => {
            const newDocs = [...prevDocs];
            newDocs.splice(index, 1);
            return newDocs;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('lpj_file', lpjFile);
        
        // Tambahkan setiap dokumen ke FormData
        documents.forEach((document) => {
            formData.append('documents[]', document);
        });

        try {
            const result = await storeLpjData(slug, formData); // Kirim slug ke fungsi store
            Swal.fire({
                html: result.message,
                icon: 'success'
            }).then(() => {
                document.location.href = `/riwayat-proposal/detail/${slug}`;
            });
            setError(null); // Reset error jika berhasil
        } catch (err) {
            console.error('error: ', err); // Log error untuk debugging
            Swal.fire({
                text: 'Input LPJ gagal ' + (err.message || 'unknown error'),
                icon: 'error'
            });
            setSuccess(null); // Reset success jika ada error
        }
    };

    return (
        <div className="md:mb-[5rem] md:mt-[-5rem] mb-4 mt-[-10px] container mx-auto md:px-[5rem] px-[1rem] z-[2] relative">
            <div className="border-gray-300 border-2 rounded-lg shadow-xl bg-white">
                <div className="py-4 md:px-10 px-4">
                    <h1 className="text-center font-semibold md:text-[2rem] mb-3 text-[1.2rem]">Input Laporan Pertanggungjawaban</h1>
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className="my-4">
                                <h2 className="font-semibold md:text-[1.2rem]">Data Dokumen</h2>
                                <p>Silahkan pilih dokumen LPJ yang sesuai lalu upload</p>
                            </div>

                            <div className="md:grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 mb-4">
                                <div className="md:mb-0 mb-3 md:mx-0 mx-3">
                                    <label className="md:text-[1rem] text-[.9rem]">File LPJ (PDF) *</label>
                                    <label htmlFor="lpj-file" className="md:text-[1rem] text-[.9rem]">
                                        <div className="w-full min-h-[150px] border-dashed border-2 rounded-lg border-red-500 flex items-center justify-center mt-1">
                                            <div className="text-center">
                                                <FontAwesomeIcon icon={faUpload} className="bg-gray-200 rounded-lg p-2" /><br />
                                                { !lpjFile ? (
                                                    <>
                                                        <span className="text-gray-400">Pilih File</span><br />
                                                        <span>format file pdf</span>
                                                    </>
                                                ) : (
                                                    <span className="text-gray-400">{lpjFile.name}</span>
                                                )}
                                            </div>
                                        </div>
                                    </label>
                                    <input 
                                        type="file" 
                                        name="lpj-file" 
                                        id="lpj-file" 
                                        className="hidden"
                                        onChange={handleLpjFileChange}
                                        accept="application/pdf"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="md:grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 mb-4">
                                {documents.map((document, index) => (
                                    <div className="md:mb-0 mb-3 md:mx-0 mx-3" key={index}>
                                        <label className="md:text-[1rem] text-[.9rem] lg:mr-40 md:mr-20">Dokumen Lainnya</label>
                                        { index > 0 && <FontAwesomeIcon icon={faTrash} className="text-red-500 ms-auto cursor-pointer justify-right items-right" onClick={() => removeDocument(index)} /> }
                                        <label htmlFor={`additional-documents-${index}`} className="md:text-[1rem] text-[.9rem]">
                                            <div className="w-full min-h-[150px] border-dashed border-2 rounded-lg border-gray-500 flex items-center justify-center mt-1">
                                                <div className="text-center">
                                                    <FontAwesomeIcon icon={faUpload} className="bg-gray-200 rounded-lg p-2" /><br />
                                                    { !document ? (
                                                        <>
                                                            <span className="text-gray-400">Pilih File</span><br />
                                                            <span>pdf, jpg, png</span>
                                                        </>
                                                    ) : (
                                                        <span className="text-gray-400">{document.name}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </label>
                                        <input 
                                            type="file" 
                                            name="additional-documents" 
                                            id={`additional-documents-${index}`} 
                                            className="hidden"
                                            onChange={(e) => handleDocumentsChange(e, index)}
                                            accept="image/jpg, image/png, application/pdf"
                                        />
                                    </div>
                                ))}
                                <div className="md:mb-0 mb-3 md:mx-0 mx-3">
                                <label className="md:text-[1rem] text-[.9rem]">Tambah Dokumen Lainnya</label>
                                    <button type="button" onClick={addDokumen} className="w-full min-h-[150px] border-dashed border-gray-300 border-2 rounded-lg flex items-center justify-center mt-1">
                                        <FontAwesomeIcon icon={faPlus} className="bg-primary text-white rounded-lg p-2 mr-2" />
                                        <label>Tambah Dokumen</label>
                                    </button>
                                </div>
                            </div>

                            <div className="mb-3">
                                <button className="w-full bg-primary text-white p-2 rounded-lg">Kirim LPJ</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LpjForm;
